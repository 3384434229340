import { defineStore } from 'pinia'
import axios from "axios";
import _ from 'lodash';

import {httpurl} from "@/main";

import { personStore } from "./person";

import {districtnr} from "@/constanten";

export const regestenStore = defineStore('regesten',{
    state: function (){
        return{
        /*******************************************************
                List of all Regesten assoziated with the person
                in the Editor. Editable in the Editor.
        *******************************************************/
            regestenList: null,

        /*******************************************************
            List of all Regesten assoziated with the person,
            like they are in the DB at loading Time.
            (Needed to identify changes in Regesten)
        ********************************************************/
            old_regestenList: null,

        /*******************************************************
            Data of the Regest currently shown in the Editor
        ********************************************************/
            active_data: null,
            active_id: null,

            deletedregest: true,
        }
    },

    actions:{

        async loadDeleteRegestOverId(Id) {
            this.deletedregest = false;

            axios.post(httpurl + '/update/delete/regest',
                Id,
                {
                    headers:{
                        "pass" : sessionStorage.getItem('pass'),
                        "name" : sessionStorage.getItem('userName')
                    }
                }).then(() =>{
                this.deletedregest = true;
            });
        },

        loadRegestenList: async function() {
            this.resetData();
            const personstore = personStore();
            const response = await axios.get(httpurl + '/regestswithperson/' + personstore.active_id, {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            this.regestenList = await response.data;
            this.regestenSetDistrictNr();
            this.setOldRegestenList(this.regestenList);
        },

        regestenSetDistrictNr(){
            this.regestenList.forEach(regest => {
                regest.bibls.forEach((bibl, index) => {
                    if(bibl.district.nr === 0 || bibl.district.nr === undefined) {
                        this.setDistrictNr(this.getRegestDataFromListOverID(regest.id), index);
                    }
                });

            });
        },

        setDistrictNr(regest, biblindex){
            regest.bibls[biblindex].district.nr = this.translateDistrictToNr(regest.bibls[biblindex].district.name);
        },

        addNewRegest: async function(){ this.saveNewRegest()},

        getRegestDataFromListOverID(id){ return  this.regestenList.filter(obj => { return obj.id.includes(id);})[0]; },

        getIndexOfRegestInListOverID(id){return this.regestenList.findIndex(obj => obj.id == id);},

        setOldRegestenList: function(json) { this.old_regestenList = JSON.parse(JSON.stringify(json)); },

        setActiveId: function(id){this.active_id = id;},

        setActiveData: function(data){
            this.active_data = data;
            this.setActiveId(this.active_data.id);
            },

        setActiveDataOverID: function (id){
            this.setActiveData(this.getRegestDataFromListOverID(id));
            this.setActiveId(id);
            },

        resetActiveData: function (){
          this.active_data = null;
          },

        resetActiveId: function (){
            this.active_id = null;
            },

        resetActive: function(){
          this.resetActiveData();
          this.resetActiveId();
          },

        resetData: function(){
            this.regestenList = null;
            this.active_data = null;
            this.old_regestenList=  null;
            this.resetActive();
        },

        addLegalTerm: function () { this.active_data.legalterms.push('none'); },

        removeLegalTerm: function(index) { this.active_data.legalterms.splice(index, 1); },

        addTopo: function (newtopo) {
            console.log(newtopo)
            this.active_data.places.push(newtopo); },

        removeTopographie: function(index) { this.active_data.places.splice(index, 1); },


        addBibliographie: function(){
            this.active_data.bibls.push({"district": "unbekannt",
                "note":"",
                "source":"",
                "type":""
            });
        },

        removeBibliographie: function (index){ this.active_data.bibls.splice(index, 1); },

        resetDateTypeWhen: function (){
            this.active_data.normalizeDate['when'] = '';
        },

        resetDateTypePeriod: function (){
            this.active_data.normalizeDate['from'] = '';
            this.active_data.normalizeDate['to'] = ''
        },


        saveActiveRegestData: function(){
                return axios.post(httpurl + '/update/regest', this.active_data, {
                    headers:{
                        "pass" : sessionStorage.getItem('pass'),
                        "name" : sessionStorage.getItem('userName')
                    }
                }).then((response) =>{
                    if("error" in response.data){console.log(response);}
                    else{ return true }
                })
            },

        saveRegestData: function(data){
            return axios.post(httpurl + '/update/regest', data, {
                headers:{
                    "pass" : sessionStorage.getItem('pass'),
                    "name" : sessionStorage.getItem('userName')
                }
            }).then((response) =>{
                if("error" in response.data){console.log(response);}
                else{ return true }
            })
        },

        saveNewRegest: function (){
            const personstore = personStore();
            let asksamnr = personstore.active_data.asksam;
            let index = this.regestenList.length +1;
            var persid = "";
            if(personstore.active_data.type == 'begine'){ persid = personstore.active_data.id; }
            else { persid = personstore.active_data.lastbegineid;}
            const data = {'id': asksamnr, 'index': index, 'persid': persid}
            axios.post(httpurl + '/new/regest',
                data,
                {
                    headers:{
                        "pass" : sessionStorage.getItem('pass'),
                        "name" : sessionStorage.getItem('userName')
                    }
                }).then((response) =>{
                this.showregesten = true;
                if("error" in response.data){console.log(response);}
                else{
                    this.regestenList.push(response.data);
                    this.old_regestenList.push(JSON.parse(JSON.stringify(response.data)));
                    this.active_data = response.data;
                }
            });
        },

        removePerson: function(index) {
            this.active_data.persons.splice(index, 1)
        },

        getOldRegestData: function (id){
            return this.old_regestenList.filter(obj => {return obj.id === id})[0];
        },

        compareRegestenList: function(){
            let comparedregesten = [];
            this.regestenList.forEach((regest) => {
                let regestid = regest.id;
                let oldregest = this.getOldRegestenData(regestid);
                comparedregesten.push(
                    {
                        id: regestid,
                        equal: _.isEqual(regest, oldregest)
                    }
                )
            });
            return comparedregesten;

        },

        compareRegestenData: function (regest1, regest2){
            if(_.isEqual(regest1, regest2)){
                return true
            }
            else{
                let result = {
                 date: _.isEqual(regest1.date, regest2.date),
                 bibl: _.isEqual(regest1.bibl, regest2.bibl),
                 legalterms: _.isEqual(regest1.legalterms, regest2.legalterms),
                 text: _.isEqual(regest1.text, regest2.text),
                 persons: _.isEqual(regest1.persons, regest2.persons),
                 places: _.isEqual(regest1.places, regest2.places),
                 convents: _.isEqual(regest1.convents, regest2.convents),
                 regnotes: _.isEqual(regest1.regestnotes, regest2.regestnotes)
                };
                return result

            }
        },

        translateDistrictToNr: function(district){ return  Object.keys(districtnr).find(key => {return districtnr[key] === district })},

        sortRegestenListOverData: function (){
            this.regestenList.sort(this.compareRegestenOverDate); },

        compareRegestenOverDate(a, b) {
            if( a.date.normalizeddate.sortover < b.date.normalizeddate.sortover ) {
                return -1
            }
            if (a.date.normalizeddate.sortover > b.date.normalizeddate.sortover) {
                return 1
            }
            return 0
        },

        getIdnoOverId: function (id){
            let regest = this.regestenList.filter(regest => regest.id === id);
            return regest[0].idno;
        }

    },

    getters:{

        getActiveDate: function(){
            if(this.active_data.date.normalizeddate.type == "when"){
                return this.active_data.date.normalizeddate.when;
            }
            else
            {
                let datestring = this.active_data.date.normalizeddate.from +' bis '+ this.active_data.date.normalizeddate.to
                return datestring
            }
        },

        getUnequealRegestenList: function(){
            let unequalregesten = [];
            this.regestenList.forEach((regest) => {
                let regestid = regest.id;
                let oldregest = this.getOldRegestData(regestid);
                if(!_.isEqual(regest, oldregest)){
                    unequalregesten.push(regestid)
                }
            });
            return unequalregesten;
        },
    }
})