import { defineStore } from 'pinia'
import {httpurl} from "@/main";
import axios from "axios";

export const entriesStore = defineStore('entries',{
    state: function (){
        return{
            entries:[],
            searchresult: {}

        }
    },

    actions:{
        async loadEntries() {
            const response = await axios.get( httpurl+ '/entries', {
                withCredentials: true,
                headers: {
                    apikey: sessionStorage.getItem('sessionId')
                }
            }).catch(error => { console.log(error) })
            this.entries = response.data
            this.searchresult = this.entries
        },
        
        searchEntries(searchstring) {
            if (searchstring.length >= 1) {
                let isnum = /^\d+$/.test(searchstring);
                if (isnum){
                    this.searchresult = this.entries.filter(obj => {return obj.nr.includes(searchstring)})
                }
                else{this.searchresult = this.entries.filter(obj => { let string = obj.name.forename.toLowerCase()+ ' ' +obj.name.surname.toLowerCase(); return string.includes(searchstring.toLowerCase())})
                }
            }
            else {
                this.searchresult = this.entries;
            }
        },

        resetSearchResult(){
            this.searchresult = this.entries
        }

    },
    getters:{

    }
})