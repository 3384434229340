//third-party
import { defineStore } from 'pinia'
import {httpurl} from "@/main";
import axios from "axios";
import _ from 'lodash';

// stores
import {personListStore } from "@/stores/personList";
import {entriesStore} from "@/stores/entries";

export const personStore = defineStore('person',{
    state: function (){
        return{
        /*******************************************************
                Data about actual in Editor editable Person
        *******************************************************/
            active_data: null,
            active_id: null,

        /*******************************************************
                Last in Editor Active Persondata
        *******************************************************/
            prev_data: null,
            prev_id: null,

        /*******************************************************
                Temporary Data (Delete after each use)
                example: Data about Person in
                ../componentes/AddTPersonTemplate.vue
        *******************************************************/
            temp_data: null,
            temp_id: null,

        /*******************************************************
                Data like they are in the DB at loading Time.
        *******************************************************/
            old_data: null,


        /*******************************************************
                Variables to track save status
         *******************************************************/
            savepersondatadone: false,
            saverelationsdone:  true,
            deleterelationsdone: false,
            savesameasdone: false,
            saveerror: null,
            newbeginedone: true,

            deletedperson: true,

        }

    },

    actions:{
        /*******************************************************
                RESt-API calls
         *******************************************************/
        callLoadPersonData: async function(id) {
            const url = httpurl + '/person/id/' + id
            const response = await axios.get(url,  {
                headers: {
                    "Content-Type": "application/json",
                }
            });
            return await response.data
        },

        callPost: async function(url, data){
           return axios.post(url, data, {
                headers: {
                    "pass": sessionStorage.getItem('pass'),
                    "name": sessionStorage.getItem('userName')
                }
            });
        },

        callSavePersonData: async function(data){
            const personliststore = personListStore();
            await this.callPost(httpurl+'/new/person', data).then((response) => {
                this.temp_data = response.data;
                personliststore.addPersonToList(response.data);
            });
        },


        callSaveBegine: async function (data){
            this.newbeginedone = false;
            const entriestore = entriesStore();
            await this.callPost(httpurl+'/new/begine', data).then((response) => {
                this.temp_data = response.data;
                entriestore.entries.push(response.data);
                entriestore.resetSearchResult();
                this.newbeginedone = true;

            });
        },

        callUpdatePersonData: async function() {
            let newpersdata = JSON.parse(JSON.stringify(this.active_data));
            const data = newpersdata;
            console.log(data)
            await this.callPost(httpurl+'/update/person', data).then(() =>{
                this.old_data = JSON.parse(JSON.stringify(this.active_data));
                this.savepersondatadone = true;
            });
        },

        callSaveSameAs: async function () {
            const data = {"sameasid": this.personData.sameas.id, "persid": this.personData.id}
            await this.callPost(httpurl+'/update/sameas', data).then(response =>{
                if("error" in response.data){this.saveerror = true;}
                this.savesameasdone = true;
            })
        },

        callDeletePerson: async function (){
            this.deletedperson = false;
            await this.callPost(httpurl+'/delete/person', this.active_id).then(() => {
                this.deletedperson = true;
            });
        },

        /*******************************************************
                general methods for all kind of PersonData:
                            active_ prev_ temp_
        *******************************************************/

        saveData() {
                this.callUpdatePersonData()
                this.compareSameAs()
            },

       personsAreEqual: function(){
            let a = JSON.stringify(this.old_data);
            let b = JSON.stringify(this.active_data);
            console.log(b);
            console.log(a);
            console.log(a === b);
       },

        dataIsEqual: function (a, b){
            if(_.isEqual(a, b)){
                return true;
            }
            else{
                return false;
            }
        },

        compareSameAs: function(){
            if(!_.isEqual(this.active_data.sameas, this.old_data.sameas)){
                console.log("same as ist anders");
                this.callSaveSameAs();
            }
            else{ this.savesameasdone = true; }
        },

        deleteRelations: async function(newrelations, oldrelations){
            const deleterelations = [];
            for (const relation of oldrelations){
                console.log(relation.id);
                console.log(newrelations.some(rel => rel.id === relation.id));
                if(!newrelations.some(rel => rel.id === relation.id)){
                    deleterelations.push(this.callDeleteRelation(relation.id));
                }
            }
            Promise.all(deleterelations).then((results) =>{
                console.log("await deleted relations")
                console.log(results);
                this.deleterelationsdone = true;
            });
        },


        removeRelation: function(index) {
            this.active_data.relations.splice(index, 1)
        },

        resetAllData: function(){
            this.resetTempData();
            this.resetActiveData();
            this.resetOldData();
            this.resetSaveStates();
        },

        resetSaveStates: function (){
            this.savepersondatadone = false;
            this.saverelationsdone = false;
            this.deleterelationsdone = false;
            this.savesameasdone = false;
        },

        /*******************************************************
                methods for active_ PersonData:
        *******************************************************/

        getActiveRelationsOverType: function (type){
            const personliststore = personListStore();
            let relatives = this.active_data.relations.filter(obj => { return obj.type === type });
            let relations = [];

            relatives.forEach((relative) => relations.push(personliststore.getPersonOverId(relative.id)) );
            return relations
        },

        /** load a new active Person into active_data,  **/
        setActiveData: async function() {
            if(this.active_id) {
                this.active_data = await this.callLoadPersonData(this.active_id);
                this.setOldData(this.active_data);
            }
            else{console.log("can't load active_data, no active_id available");}
        },

        setActiveId: function(id) { this.active_id = id; },

        resetActiveData: function(){
          this.active_data = null;
          this.active_id = null;
          },



        /*******************************************************
                    methods for prev_ PersonData
        *******************************************************/

        setPrevId: function(id){this.prev_id = id},

        /*******************************************************
                    methods for temp_ PersonData
        *******************************************************/

        getTempData: async function(id) { this.temp_data = await this.callLoadPersonData(id) },

        setTempData: function(data){ this.temp_data = data; },

        resetTempData: function(){ this.temp_data = null; },

        setTempPersonID: function(id){ this.temp_id = id; },

        saveNewTempData: async function(){ await this.callSavePersonData(this.temp_data);
            return this.temp_data;},

        /*******************************************************
                    methods for old_ PersonData
         *******************************************************/

        setOldData: function (json) { this.old_data = JSON.parse(JSON.stringify(json)); },

        resetOldData: function (){this.old_data = null;},

    },
    getters:{
        /*******************************************************
                    getters for active_ PersonData
         *******************************************************/
        activeFromDate: function() {
            let splitted = this.active_data.active.from.split('-');
            return splitted[2] + '.' + splitted[1] + '.' + splitted[0];
        },

        activeToDate: function () {
            let splitted = this.active_data.active.to.split('-');
            return splitted[2] + '.' + splitted[1] + '.' + splitted[0];
        },

        activeParentsArray: function () {
            const personliststore = personListStore();
            let relatives = this.active_data.relations.filter(obj => { return obj.type === "parent" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },

        activeSiblingsArray: function () {
            const personliststore = personListStore();
            let relatives = this.active_data.relations.filter(obj => { return obj.type === "sibling" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },

        activeChildArray: function () {
            const personliststore = personListStore();
            let relatives  = this.active_data.relations.filter(obj => { return obj.type === "child" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },

        activeSpouseArray: function () {
            const personliststore = personListStore();
            let relatives = this.active_data.relations.filter(obj => { return obj.type === "spouse" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },
        /*******************************************************
                    getters for prev_ PersonData
         *******************************************************/

        /*******************************************************
                    getters for temp_ PersonData
         *******************************************************/
        tempFromDate: function() {
            let splitted = this.temp_data.active.from.split('-');
            return splitted[2] + '.' + splitted[1] + '.' + splitted[0];
            },

        tempToDate: function () {
            let splitted = this.temp_data.active.to.split('-');
            return splitted[2] + '.' + splitted[1] + '.' + splitted[0];
            },

        tempParentsArray: function () {
            const personliststore = personListStore();
            let relatives = this.temp_data.relations.filter(obj => { return obj.type === "parent" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
        },

        tempSiblingsArray: function () {
            const personliststore = personListStore();
            let relatives = this.temp_data.relations.filter(obj => { return obj.type === "sibling" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names
            },

        tempChildArray: function () {
            const personliststore = personListStore();
            let relatives  = this.temp_data.relations.filter(obj => { return obj.type === "child" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names },

        tempSpouseArray: function () {
            const personliststore = personListStore();
            let relatives = this.temp_data.relations.filter(obj => { return obj.type === "spouse" });
            let names = [];
            relatives.forEach((relative) => names.push(personliststore.getPersonNameOverId(relative.id)) );
            return names},



    }
})