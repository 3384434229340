<template>
  <div class=" grid grid-cols-3 fixed top-[25%] left-[35%] z-20 bg-gray-100 border-red-500 rounded border-4 w-1/3 h-1/3 pl-4 pt-4">
    <div v-if="persondatachanged || unequalregestenids.length > 0" class="col-start-1 col-span-3 grid grid-cols-subgrid grid-cols-3">
      <template  v-if="persondatachanged && !savepersondone">
        <div class="col-start-1 col-span-3">Änderungen in Person:
          <div class="col-start-2 flex gap-2"><span>{{active_data.idno}}</span><SavePerson :save="save" @done="personSaveDone"/></div>
        </div>
      </template>
      <template v-if="unequalregestenids.length > 0">
        <div class="col-start-1 col-span-3">Änderungen in Regesten:
          <ul class="flex gap-2">
            <template  v-for="id in unequalregestenids" :key="id">
              <li>{{regestenStore.getIdnoOverId(id)}}</li>
              <SaveRegest :save="save" :regestidno="regestenStore.getIdnoOverId(id)" :regestid="id"  @done="regestSaveDone(idno)"/>
            </template>
          </ul>
        </div>
      </template>
      <template v-if="save">
      <button disabled class="bg-gray-200 outline-none mr-4 border-2 rounded-md border-red-500 p-1 mt-auto mb-auto w-20" @click="saveData">Speichern</button>
      <button disabled class="bg-gray-200 outline-none mr-4 border-2 rounded-md border-red-500 p-1 mt-auto mb-auto w-20" @click="closePopup">Abbruch</button>
      </template>
      <template v-else>
        <button class="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-red-500 p-1 mt-auto mb-auto w-20" @click="saveData">Speichern</button>
        <button class="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-red-500 p-1 mt-auto mb-auto w-20" @click="closePopup">Abbruch</button>
      </template>
    </div>
    <div v-else-if="allsavedone" class="col-start-1 col-span-3 grid grid-cols-subgrid grid-cols-3">
      <div>Alles Gespeichert</div>
      <button class="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-red-500 p-1 mt-auto mb-auto w-20" @click="closePopup">OK</button>
    </div>
    <div v-else-if="mode === 'manuel'" class="col-start-1 col-span-3 grid grid-cols-subgrid grid-cols-3">
      <div>Nichts zu speichern</div>
      <button class="hover:bg-gray-200 outline-none mr-4 border-2 rounded-md border-red-500 p-1 mt-auto mb-auto w-20" @click="closePopup">OK</button>
    </div>
  </div>


</template>

<script>

import {mapState} from "pinia";

import SaveRegest from "./SaveRegest.vue"
import SavePerson from "./SavePerson.vue";

import {regestenStore} from "@/stores/regesten";
import {personStore} from "@/stores/person";


export default {
  name: "SaveTemplate.vue",
  components: {SaveRegest, SavePerson},
  emits: ['savedone', 'close-popup', 'saveerror'],

  props: [ 'persidno', 'mode' ],
  data() {
    return {
      unequalregestenids: [],
      save: false,
      savepersondone: false,
      saveregestendone: false,
      persondatachanged: false,
      allsavedone: false,
    }
  },
  computed:{
    regestenStore: function(){ return regestenStore(); },
    ...mapState(regestenStore, [
      'old_regestenList',
      'active_id',
      'active_data',
      'getUnequealRegestenList'
    ]),

    personStore: function (){ return personStore();},
    ...mapState(personStore, [
        'active_data',
        'old_data'
    ]),
  },

  created() {
    this.persondatachanged = !this.personStore.dataIsEqual(this.personStore.active_data, this.personStore.old_data);
    this.unequalregestenids =  this.getUnequealRegestenList;
    if(this.unequalregestenids.length === 0){this.saveregestendone = true}
    if(!this.persondatachanged){this.savepersondone = true}
  },

  watch: {
    saveregestendone(newvalue){
      if(newvalue === true && this.savepersondone === true && this.mode === 'auto'){ this.closePopup(); }
      else if(newvalue === true && this.savepersondone === true){ this.allsavedone = true }
      },

    savepersondone(newvalue){
      if(newvalue === true && this.saveregestendone === true && this.mode === 'auto'){ this.closePopup(); }
      else if(newvalue === true && this.saveregestendone === true){ this.allsavedone = true}
    },
  },

  methods: {

    regestSaveDone: function (id) {
      this.unequalregestenids.splice(this.unequalregestenids.indexOf(id), 1);
      if(this.unequalregestenids.length === 0){ this.saveregestendone = true; }
    },

    personSaveDone: function () {
      this.savepersondone = true
      this.persondatachanged = false;
    },

    saveData: function () {
      this.save = true;
    },


    closePopup: function () {
      this.save = false;
      this.personStore.savepersondatadone = false;
      this.$emit("close-popup");
    },

  }
}
</script>



<style scoped></style>